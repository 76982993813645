import img318bb7a02ee3d_001_webp from '../img/products/Yen Rut Long Kho/318bb7a02ee3d_001.webp';
import img318bb7a02ee3d_002_webp from '../img/products/Yen Rut Long Kho/318bb7a02ee3d_002.webp';
import img318bb7a02ee3d_003_webp from '../img/products/Yen Rut Long Kho/318bb7a02ee3d_003.webp';
import img440cb845c72e1_001_webp from '../img/products/Yen Rut Long Kho/440cb845c72e1_001.webp';
import img440cb845c72e1_002_webp from '../img/products/Yen Rut Long Kho/440cb845c72e1_002.webp';
import img440cb845c72e1_003_webp from '../img/products/Yen Rut Long Kho/440cb845c72e1_003.webp';
import img57f82f074d9af_001_webp from '../img/products/Yen Rut Long Kho/57f82f074d9af_001.webp';
import img57f82f074d9af_002_webp from '../img/products/Yen Rut Long Kho/57f82f074d9af_002.webp';
import imge1362a1c72141_001_webp from '../img/products/Yen Tho/e1362a1c72141_001.webp';
import imge1362a1c72141_002_webp from '../img/products/Yen Tho/e1362a1c72141_002.webp';
import imgeb402c3a2a501a_001_webp from '../img/products/Yen Tho/b402c3a2a501a_001.webp';
import img51453ff801875_001_webp from '../img/products/Chan Yen Rut Long/51453ff801875_001.webp';
import img51453ff801875_002_webp from '../img/products/Chan Yen Rut Long/51453ff801875_002.webp';
import imgec23f5702ae68_001_webp from '../img/products/Chan Yen Tho/ec23f5702ae68_001.webp';
import img06f1a8efc291c_001_webp from '../img/products/Yen Tinh Che/06f1a8efc291c_001.webp';
import imgbe1d80cfd8008_001_webp from '../img/products/Yen Tinh Che/be1d80cfd8008_001.webp';
import imgbe1d80cfd8008_002_webp from '../img/products/Yen Tinh Che/be1d80cfd8008_002.webp';
import imgbe1d80cfd8008_003_webp from '../img/products/Yen Tinh Che/be1d80cfd8008_003.webp';
import imgf35796a06df51_001_webp from '../img/products/Yen Tinh Che/f35796a06df51_001.webp';
import imgf35796a06df51_002_webp from '../img/products/Yen Tinh Che/f35796a06df51_002.webp';
import img318bb7a02ee3d_001_png from '../img/products/Yen Rut Long Kho/318bb7a02ee3d_001.png';
import img318bb7a02ee3d_002_png from '../img/products/Yen Rut Long Kho/318bb7a02ee3d_001.png';
import img318bb7a02ee3d_003_png from '../img/products/Yen Rut Long Kho/318bb7a02ee3d_001.png';
import img440cb845c72e1_001_png from '../img/products/Yen Rut Long Kho/440cb845c72e1_001.png';
import img440cb845c72e1_002_png from '../img/products/Yen Rut Long Kho/440cb845c72e1_002.png';
import img440cb845c72e1_003_png from '../img/products/Yen Rut Long Kho/440cb845c72e1_003.png';
import img57f82f074d9af_001_png from '../img/products/Yen Rut Long Kho/57f82f074d9af_001.png';
import img57f82f074d9af_002_png from '../img/products/Yen Rut Long Kho/57f82f074d9af_002.png';
import imge1362a1c72141_001_png from '../img/products/Yen Tho/e1362a1c72141_001.png';
import imge1362a1c72141_002_png from '../img/products/Yen Tho/e1362a1c72141_002.png';
import imgeb402c3a2a501a_001_png from '../img/products/Yen Tho/b402c3a2a501a_001.png';
import img51453ff801875_001_png from '../img/products/Chan Yen Rut Long/51453ff801875_001.png';
import img51453ff801875_002_png from '../img/products/Chan Yen Rut Long/51453ff801875_002.png';
import imgec23f5702ae68_001_png from '../img/products/Chan Yen Tho/ec23f5702ae68_001.png';
import img06f1a8efc291c_001_png from '../img/products/Yen Tinh Che/06f1a8efc291c_001.png';
import imgbe1d80cfd8008_001_png from '../img/products/Yen Tinh Che/be1d80cfd8008_001.png';
import imgbe1d80cfd8008_002_png from '../img/products/Yen Tinh Che/be1d80cfd8008_002.png';
import imgbe1d80cfd8008_003_png from '../img/products/Yen Tinh Che/be1d80cfd8008_003.png';
import imgf35796a06df51_001_png from '../img/products/Yen Tinh Che/f35796a06df51_001.png';
import imgf35796a06df51_002_png from '../img/products/Yen Tinh Che/f35796a06df51_002.png';

export const ourProducts = [
  {
    title: 'Yến Rút Lông Khô 100g',
    images: [
      {
        png: img318bb7a02ee3d_001_png,
        webp: img318bb7a02ee3d_001_webp,
        desc: 'Picture of Yến Rút Lông Khô laying in a basket',
      },
      {
        png: img318bb7a02ee3d_002_png,
        webp: img318bb7a02ee3d_002_webp,
        desc: 'Picture of Yến Rút Lông Khô laying in a basket',
      },
      {
        png: img318bb7a02ee3d_003_png,
        webp: img318bb7a02ee3d_003_webp,
        desc: 'Picture of Yến Rút Lông Khô laying in a basket',
      },
    ],
    price: '4,200.000 ',
    rating: 5,
  },
  {
    title: 'Yến Rút Lông Khô 50g',
    images: [
      {
        png: img440cb845c72e1_001_png,
        webp: img440cb845c72e1_001_webp,
        desc: 'Picture of Yến Rút Lông Khô laying in a basket',
      },
      {
        png: img440cb845c72e1_002_png,
        webp: img440cb845c72e1_002_webp,
        desc: 'Picture of Yến Rút Lông Khô laying in a basket',
      },
      {
        png: img440cb845c72e1_003_png,
        webp: img440cb845c72e1_003_webp,
        desc: 'Picture of Yến Rút Lông Khô laying in a basket',
      },
    ],
    price: '2,100.000 ',
    rating: 5,
  },
  {
    title: 'Yến Rút Lông Khô 30g',
    images: [
      {
        png: img57f82f074d9af_001_png,
        webp: img57f82f074d9af_001_webp,
        desc: 'Picture of Yến Rút Lông Khô laying in a basket',
      },
      {
        png: img57f82f074d9af_002_png,
        webp: img57f82f074d9af_002_webp,
        desc: 'Picture of Yến Rút Lông Khô laying in a basket',
      },
    ],
    price: '1,300.000 ',
    rating: 5,
  },
  {
    title: 'Yến Thô 100g',
    images: [
      {
        png: imge1362a1c72141_001_png,
        webp: imge1362a1c72141_001_webp,
        desc: 'Picture of Yến Thô laying in a basket next to gold flowers',
      },
      {
        png: imge1362a1c72141_002_png,
        webp: imge1362a1c72141_002_webp,
        desc: 'Picture of Yến Thô laying in a basket next to gold flowers',
      },
    ],
    price: '2,600.000 ',
    rating: 5,
  },
  {
    title: 'Yến Thô 50g',
    images: [
      {
        png: imgeb402c3a2a501a_001_png,
        webp: imgeb402c3a2a501a_001_webp,
        desc: 'Picture of Yến Thô laying in a basket ',
      },
    ],
    price: '1,300.000 ',
    rating: 5,
  },
  {
    title: 'Chân Yến Rút Lông 100g',
    images: [
      {
        png: img51453ff801875_001_png,
        webp: img51453ff801875_001_webp,
        desc: 'Picture of Chân Yến Rút Lông laying in a glass bowl',
      },
      {
        png: img51453ff801875_002_png,
        webp: img51453ff801875_002_webp,
        desc: 'Zoomed in picture of Chân Yến Rút Lông',
      },
    ],
    price: '3,200.000 ',
    rating: 5,
  },
  {
    title: 'Chân Yến Thô 100g',
    images: [
      {
        png: imgec23f5702ae68_001_png,
        webp: imgec23f5702ae68_001_webp,
        desc: 'Picture of Chân Yến Thô placed in a gold container',
      },
    ],
    price: '2,200.000 ',
    rating: 5,
  },
  {
    title: 'Yến Tinh Chế 100g',
    images: [
      {
        png: img06f1a8efc291c_001_png,
        webp: img06f1a8efc291c_001_webp,
        desc: 'Picture of Yến Tinh Chế laying in a basket next to a card with pink flowers ',
      },
    ],
    price: '3,600.000 ',
    rating: 5,
  },
  {
    title: 'Yến Tinh Chế 50g',
    images: [
      {
        png: imgbe1d80cfd8008_001_png,
        webp: imgbe1d80cfd8008_001_webp,
        desc: 'Picture of Yến Tinh Chế laying in bowl on top of white paper',
      },
      {
        png: imgbe1d80cfd8008_002_png,
        webp: imgbe1d80cfd8008_002_webp,
        desc: 'Picture of Yến Tinh Chế laying in bowl on top of white paper',
      },
      {
        png: imgbe1d80cfd8008_003_png,
        webp: imgbe1d80cfd8008_003_webp,
        desc: 'Zoomed in picured of Yến Tinh Chế laying on a blue mat',
      },
    ],
    price: '1,800.000 ',
    rating: 5,
  },

  {
    title: 'Yến Tinh Chế 30g',
    images: [
      {
        png: imgf35796a06df51_001_png,
        webp: imgf35796a06df51_001_webp,
        desc: 'Picure of Yến Tinh Chế  laying in a clear plastic bowl',
      },
      {
        png: imgf35796a06df51_002_png,
        webp: imgf35796a06df51_002_webp,
        desc: 'Picure of Yến Tinh Chế  laying in a clear plastic bowl next to a red card',
      },
    ],
    price: '1,100.000 ',
    rating: 5,
  },
];
