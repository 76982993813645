import emptyStar from '../img/svg/star-outline.svg';
import filledStar from '../img/svg/star-filled.svg';
import { ourProducts } from './products';

///////////////////////////////////////////////////////////
// Nav global references

const productHeadingEl = document.querySelector('.product-heading');
const productSectionEL = document.querySelector('.section-product');

const navMission = './index.html#mission';
const navWhy = './index.html#why';
const navProducts = './index.html#products';
const navTestimonial = './index.html#testimonial-section';
const navContact = './index.html#contact';
const navHome = './index.html#header';

// Setting href for all pages
const missionNodes = Array.from(document.querySelectorAll('.mission-link'));
missionNodes.forEach((el) => (el.href = navMission));
document.querySelector('.why-link').href = navWhy;
document.querySelector('.products-link').href = navProducts;
document.querySelector('.testimonial-link').href = navTestimonial;
document.querySelector('.contact-link').href = navContact;
document.querySelector('.footer-logo').href = navHome;
document.querySelector('.logo-container').href = navHome;

// Search bar defaults
document.getElementById('y-search').placeholder = 'Bạn muốn tìm gì ...';
document.getElementById('y-search-mobile').placeholder = 'Bạn muốn tìm gì ...';

const updateProductView = () => {
  const previousProducts = Array.from(document.querySelectorAll('.product'));
  const productsContainer = document.querySelector('.product-container');

  productHeadingEl.textContent = 'Search Results';
  previousProducts.forEach((el) => {
    productsContainer.removeChild(el);
  });
};

document.getElementById('search-form').addEventListener('submit', function (e) {
  e.preventDefault();
  updateProductView();
  if (!document.getElementById('y-search').value) return;
  document.getElementById('search-icon').style.display = 'none';
});

document
  .getElementById('search-form-mobile')
  .addEventListener('submit', function (e) {
    e.preventDefault();
  });

document.getElementById('y-search').addEventListener('search', function (e) {
  if (e.target.value.length > 2) {
    const searchResults = products.loadSearch(e.target.value);
    if (!searchResults)
      productHeadingEl.textContent = 'Search Results - Nothing Found';
  }
  // Clearing search results
  if (e.target.value === '') {
    document.getElementById('search-icon').style.display = 'inline-block';
    updateProductView();
    products.loadProducts();
    productHeadingEl.textContent = 'Tổ Yến';
  }
  productSectionEL.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
});

document
  .getElementById('y-search-mobile')
  .addEventListener('search', function (e) {
    updateProductView();
    if (e.target.value.length > 2) {
      const searchResults = products.loadSearch(e.target.value);
      document.querySelector('.product-heading').textContent = 'Search Results';
      if (!searchResults)
        productHeadingEl.textContent = 'Search Results - Nothing Found';
    }
    // Clearing search results
    if (e.target.value === '') {
      updateProductView();
      products.loadProducts();
      productHeadingEl.textContent = 'Tổ Yến';
    }
    productSectionEL.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
    headerEl.classList.toggle('nav-open');
  });

///////////////////////////////////////////////////////////
// Products

class ProductImages {
  constructor(imgPng, imgWebp, imgdescription) {
    this.imageSet = [imgWebp, imgPng]; //[webp, png]
    this.imgdescription = imgdescription;
  }
}

class Product {
  id = Math.random().toString(16).split('.').at(-1);
  constructor(ProductImages, title, rating, price) {
    this.images = ProductImages;
    this.title = title;
    this.rating = rating;
    this.price = price;
  }

  _stars(rating) {
    const filledStarsAmt = Math.round(rating);
    const filledStarsTemplate = `<img src="${filledStar}" class="product-icon-star" alt="Solid star icon"/>`;
    const emptyStarsTemplate = `<img src="${emptyStar}" class="product-icon-star" alt="Star icon outline"/>`;
    const elFilledStars = filledStarsTemplate.repeat(filledStarsAmt);
    const elOutlineStar = emptyStarsTemplate.repeat(5 - filledStarsAmt);
    return { elFilledStars, elOutlineStar };
  }

  _pictureMarkup(png, webp, imgDesc) {
    const markup = `
    <picture class="product-picture-${this.id}">
    <source srcset="${webp}" type="image/webp"/>
    <source srcset="${png}" type="image/png"/>
    <img
      src="${png}"
      class="product-img"
      alt="${imgDesc}"/>
    </picture>
    `;
    return markup;
  }

  createMarkup() {
    const { elFilledStars, elOutlineStar } = this._stars(this.rating);
    const productImgArr = this.images.map((el) => ({
      images: el.imageSet,
      alt: el.imgdescription,
    }));
    const picturesEl = productImgArr
      .map((el) => this._pictureMarkup(el.images[1], el.images[0], el.alt))
      .join('');
    let markup = `
    <div class="product">
    <div class="${this.id}Images product-img-container"> `;
    markup += picturesEl;
    markup += `
    </div>
    <div class="product-content">
      <p class="product-title">${this.title}</p>
      <ul class="product-attributes">
        <li class="product-attribute">
          ${elFilledStars}
          ${elOutlineStar}
        </li>
        <li class="product-attribute">
          <span class="product-description">${this.price}₫</span>
        </li>
      </ul>
    </div>`;
    return markup;
  }
}

class Products {
  constructor(products) {
    this.products = products;
  }

  loadProducts() {
    this.products.forEach((p) => {
      productContainer.insertAdjacentHTML('beforeend', p.createMarkup());
    });
  }

  _search(searchStr) {
    const words = searchStr.split(' ').filter((w) => w.length > 2);
    const filterEl = this.products.map(function (el) {
      const titleArray = el.title.split(' ').map((el) => el.toLowerCase());
      return words.every((val) => titleArray.includes(val.toLowerCase()))
        ? el
        : null;
    });
    return filterEl.filter((val) => val !== null);
  }

  loadSearch(searchStr) {
    const queryItems = this._search(searchStr);
    if (queryItems.length > 0) {
      queryItems.forEach((p) => {
        productContainer.insertAdjacentHTML('beforeend', p.createMarkup());
      });
      return true;
    }
    if (!queryItems) return null;
  }
}

const productContainer = document.querySelector('.product-container');
const productItems = [];

if (productContainer) {
  ourProducts.forEach((prod) => {
    const imgCnt = prod.images.length;
    const productImagesArr = [];
    for (let i = 0; i < imgCnt; i++) {
      productImagesArr.push(
        new ProductImages(
          prod.images[i].png,
          prod.images[i].webp,
          prod.images[i].desc,
        ),
      );
    }
    const productItem = new Product(
      productImagesArr,
      prod.title,
      prod.rating,
      prod.price,
    );
    productItems.push(productItem);
  });
}

const products = new Products(productItems);
products.loadProducts();

// rotate product images
const productSection = document.querySelector('.product-container');
const intervalIds = [];

if (productSection) {
  const productObserver = new IntersectionObserver(
    function (entries) {
      const ent = entries[0];
      if (ent.isIntersecting === true) {
        // Insert rotating func
        productItems.forEach((item) => {
          const delay = Math.trunc(Math.random() * (5000 - 4000) + 4000);
          rotateProductImages(item.id, delay);
        });
      }
      if (ent.isIntersecting === false) {
        intervalIds.forEach((id) => clearInterval(id));
        intervalIds.splice(0);
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-80px',
    },
  );

  productObserver.observe(productSection);
}

const rotateProductImages = function (productId, interval) {
  const pictures = document.querySelectorAll(`.product-picture-${productId}`);
  let currentIndex = 0;
  function showNextImage() {
    pictures[currentIndex].classList.remove('active');
    pictures[currentIndex].style.opacity = '0';
    currentIndex = (currentIndex + 1) % pictures.length;
    pictures[currentIndex].classList.add('active');
    pictures[currentIndex].style.opacity = '1';
  }
  function startImageTransition(interval) {
    pictures[currentIndex].classList.add('active');
    const intervalId = setInterval(showNextImage, interval);
    intervalIds.push(intervalId);
  }
  startImageTransition(interval);
};

///////////////////////////////////////////////////////////
// Set current year
const yearEl = document.querySelector('.year');
const currentYear = new Date().getFullYear();
yearEl.textContent = currentYear;

///////////////////////////////////////////////////////////
// Make mobile navigation work

const btnNavEl = document.querySelector('.btn-mobile-nav');
const headerEl = document.querySelector('.header');

btnNavEl.addEventListener('click', function () {
  headerEl.classList.toggle('nav-open');
});

const navLinks = Array.from(document.querySelectorAll('.main-nav-link'));
navLinks.forEach((link) => {
  link.addEventListener('click', function (e) {
    headerEl.classList.toggle('nav-open');
  });
});

///////////////////////////////////////////////////////////
// Smooth scrolling animation

const allLinks = document.querySelectorAll('a:link');

allLinks.forEach(function (link) {
  link.addEventListener('click', function (e) {
    const href = link.getAttribute('href');
    if (!href.startsWith('#')) return;
    e.preventDefault();

    // Scroll back to top
    if (href === '#')
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    // Scroll to other links
    if (href !== '#' && href.startsWith('#')) {
      const sectionEl = document.querySelector(href);
      sectionEl.scrollIntoView({ behavior: 'smooth' });
    }

    // Close mobile naviagtion
    if (link.classList.contains('main-nav-link'))
      headerEl.classList.toggle('nav-open');
  });
});

///////////////////////////////////////////////////////////
// Sticky navigation

const sectionHeroEl = document.querySelector('.section-hero');
if (sectionHeroEl) {
  const obs = new IntersectionObserver(
    function (entries) {
      const ent = entries[0];

      if (ent.isIntersecting === false) {
        document.body.classList.add('sticky');
      }

      if (ent.isIntersecting === true) {
        document.body.classList.remove('sticky');
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-80px',
    },
  );

  obs.observe(sectionHeroEl);
}
///////////////////////////////////////////////////////////
// Shrink contacts widget in Testimonials section
const sectionTestimonialEl = document.querySelector('.section-testimonials');
const widgetContainer = document.querySelector('.widget-container');

if (widgetContainer) {
  const observer = new IntersectionObserver(
    function (entries) {
      const ent = entries[0];
      if (ent.isIntersecting === false) {
        widgetContainer.classList.remove('widget-collapse');
      }

      if (ent.isIntersecting === true) {
        widgetContainer.classList.add('widget-collapse');
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-80px',
    },
  );

  observer.observe(sectionTestimonialEl);
}
///////////////////////////////////////////////////////////
// Hide contacts widget on footer section
const sectionFooterEl = document.querySelector('.footer');

if (widgetContainer) {
  const observerFooter = new IntersectionObserver(
    function (entries) {
      const ent = entries[0];
      if (ent.isIntersecting === false) {
        widgetContainer.classList.remove('widgets-container-hide');
      }

      if (ent.isIntersecting === true) {
        widgetContainer.classList.add('widgets-container-hide');
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-100px',
    },
  );

  observerFooter.observe(sectionFooterEl);
}

///////////////////////////////////////////////////////////
// Fixing flexbox gap property missing in some Safari versions
function checkFlexGap() {
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1;
  flex.parentNode.removeChild(flex);

  if (!isSupported) document.body.classList.add('no-flexbox-gap');
}
checkFlexGap();
